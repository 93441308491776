import {
  EngagementBudgetStatusType,
  EngagementWorkStatusType,
} from "@m/api/public/types";
import { TBadgeStatus } from "@m/ui";

export enum ENGAGEMENTS_BUDGET_STATUS_DISPLAY_NAME {
  ON_PLANNED_COST = "Within Budget",
  UNDER_PLANNED_COST = "Under Budget",
  OVER_PLANNED_COST = "Over Budget",
}

export enum ENGAGEMENT_TYPE_DISPLAY_NAME {
  ASSESS = "MAP Assess",
  MOBILIZE = "MAP Mobilize",
  OTHER = "Other",
}

export const MESSAGES_ENGAGEMENTS_TEMPLATE_FILL = {
  GET_ERROR: "Unable to retreive template details",
};

export const MESSAGES_ENGAGEMENTS_STATUS = {
  EMPTY: "Unable to find status data",
  GET_ERROR: "Unable to retreive project status",
  UPDATE_SUCCESS: "Successfully updated the project status",
  UPDATE_ERROR: "Unable to update project status",
};

export const MESSAGES_ENGAGEMENTS_WORK_STATUS = {
  GET_ENGAGEMENT_WORK_STATUS_ERROR:
    "Unable to retrieve engagement work status.",
  UPDATE_WORK_STATUS_ERROR: "Unable to update work status details.",
  UPDATE_WORK_STATUS_SUCCESS: "Successfully updated the work status details.",
};

export const MESSAGES_ENGAGEMENTS_BUDGET = {
  GET_BUDGET_ERROR: "We encountered an issue retrieving budget status details.",
  UPDATE_BUDGET_ERROR: "Unable to update budget status details.",
  UPDATE_BUDGET_SUCCESS: "Successfully updated the budget status details.",
};

export const ENGAGEMENT_BUDGET_BADGE_STATUS: {
  [ENGAGEMENTS_BUDGET_STATUS: string]: TBadgeStatus;
} = {
  [EngagementBudgetStatusType.OnPlannedCost]: "positive",
  [EngagementBudgetStatusType.OverPlannedCost]: "negative",
  [EngagementBudgetStatusType.UnderPlannedCost]: "positive",
};

export const ENGAGEMENT_WORK_BADGE_STATUS: {
  [ENGAGEMENTS_WORK_STATUS: string]: TBadgeStatus;
} = {
  [EngagementWorkStatusType.OnSchedule]: "positive",
  [EngagementWorkStatusType.BehindSchedule]: "negative",
  [EngagementWorkStatusType.AheadOfSchedule]: "positive",
};
