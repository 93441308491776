import { RouteObject } from "react-router-dom";

import { SKUS } from "@mc/constants";
import { OnboardingWelcomePage } from "@mc/features/Onboarding";

import { onboardingCloudGatewayRoutes } from "./onboardingCloudGatewayRoutes";
import { onboardingCloudScoreRoutes } from "./onboardingCloudScoreRoutes";
import { OnboardingProductOutlet } from "./outlets";
import { OnboardingPathRedirect } from "./redirects";

export const onboardingRoutes: RouteObject[] = [
  {
    path: "*",
    element: <OnboardingPathRedirect />,
  },
  {
    path: "welcome",
    element: <OnboardingWelcomePage />,
  },
  {
    path: "cloud-score",
    element: <OnboardingProductOutlet sku={SKUS.CLOUD_SCORE} />,
    children: onboardingCloudScoreRoutes,
  },
  {
    path: "cloud-gateway",
    element: <OnboardingProductOutlet sku={SKUS.CLOUD_GATEWAY} />,
    children: onboardingCloudGatewayRoutes,
  },
];
