import { LinkIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { ChangeEvent, FormEventHandler, useMemo, useState } from "react";

import { Button, Field, Fields, Input, Modal, Select } from "@m/ui";

import { SKUS } from "@mc/constants";
import { WORK_EMAIL_PATTERN } from "@mc/utils/emails";

import {
  EligibleProductsForInvitationType,
  useCompanyWorkspaces,
  useCreateMarketplaceInvitation,
} from "../api";

interface Props {
  companyName: string;
  onClose: () => void;
  open: boolean;
  productsForInvitation: EligibleProductsForInvitationType;
}

export const MarketplaceInviteDialog = ({
  companyName,
  onClose,
  open,
  productsForInvitation,
}: Props) => {
  const [createMarketplaceInvitation, { loading }] =
    useCreateMarketplaceInvitation();

  const {
    data: { workspaces },
  } = useCompanyWorkspaces();

  const [productSku, setProductSku] = useState(productsForInvitation[0]?.sku);
  const [offerUrl, setOfferUrl] = useState(null);

  const workspacesOptions = useMemo(() => {
    return workspaces.map((workspace) => {
      if (!workspace.isAssociatedWithEngagement) {
        return (
          <option key={workspace.id} value={workspace.id}>
            {workspace.title}
          </option>
        );
      }
    });
  }, [workspaces]);

  const handleChangeProductSku = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setProductSku(value);
  };

  const handleChangeOfferUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOfferUrl(value === "" ? null : value);
  };

  const handleSubmit: FormEventHandler<MarketplaceInviteFormElement> = (e) => {
    e.preventDefault();

    const {
      emailInput,
      productSelect,
      awsAccountIdInput,
      privateOfferUrlInput,
      projectSelect,
    } = e.currentTarget.elements;

    const sourceId = projectSelect?.value;
    const sourceType = sourceId
      ? workspaces.find((workspace) => workspace.id === projectSelect?.value)
          .projectType
      : null;

    createMarketplaceInvitation({
      email: emailInput.value,
      awsAccountId: awsAccountIdInput?.value,
      privateOfferUrl: privateOfferUrlInput?.value,
      sku: productSelect.options?.[productSelect.selectedIndex].value,
      ...(sourceId && { sourceId, sourceType }),
    });
    onClose();
  };

  return (
    <Modal title="Send New Private Offer" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Fields>
          <Field label="Company" htmlFor="company">
            <Input value={companyName} disabled={true} id="company" />
          </Field>
          <Field label="Email" htmlFor="email">
            <Input
              id="email"
              name="emailInput"
              pattern={WORK_EMAIL_PATTERN}
              placeholder="user@domain.com"
              required={true}
              title="Please enter a valid company email address."
              type="email"
            />
          </Field>
          <Field label="Account ID" htmlFor="aws-account-id">
            <Input
              id="aws-account-id"
              name="awsAccountIdInput"
              required={true}
              maxLength={12}
              minLength={12}
              pattern="^\d{12}$"
              title="Enter a valid AWS account ID"
            />
          </Field>
          <Field label="Marketplace Product" htmlFor="product">
            <ProductSelect
              productSku={productSku}
              eligibleProductsForInvitation={productsForInvitation}
              onChange={handleChangeProductSku}
            />
          </Field>
          {productSku === SKUS.CLOUD_ENGAGEMENTS && (
            <Field label="Kantata Project" htmlFor="project">
              <Select
                id="project"
                name="projectSelect"
                options={workspacesOptions}
                required={true}
              />
            </Field>
          )}
          <Field label="Private Offer URL" htmlFor="private-offer-url">
            <Input
              id="private-offer-url"
              name="privateOfferUrlInput"
              onChange={handleChangeOfferUrl}
              placeholder="https://aws.amazon.com/marketplace/pp/prodview-123456789abcde"
              required={true}
              leftIcon={LinkIcon}
            />
          </Field>
        </Fields>
        <Button
          className="mt-4 w-full"
          kind="primary"
          loading={loading}
          type="submit"
          disabled={offerUrl === null}
        >
          <PaperAirplaneIcon className="h-2 w-2" />
          Send Offer
        </Button>
      </form>
    </Modal>
  );
};

const ProductSelect = ({
  productSku,
  eligibleProductsForInvitation,
  onChange,
}: {
  productSku: string;
  eligibleProductsForInvitation: EligibleProductsForInvitationType;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const productOptions = useMemo(
    () =>
      eligibleProductsForInvitation.map((product) => (
        <option key={product.sku} value={product.sku}>
          {product.displayName}
        </option>
      )),
    [eligibleProductsForInvitation]
  );

  return (
    <Select
      id="product"
      name="productSelect"
      onChange={onChange}
      value={productSku}
      options={productOptions}
    />
  );
};

interface MarketplaceInviteFormElements extends HTMLFormControlsCollection {
  emailInput: HTMLInputElement;
  productSelect: HTMLSelectElement;
  privateOfferUrlInput?: HTMLInputElement;
  awsAccountIdInput?: HTMLInputElement;
  projectSelect: HTMLSelectElement;
}

interface MarketplaceInviteFormElement extends HTMLFormElement {
  readonly elements: MarketplaceInviteFormElements;
}
