import { EngagementBudgetStatusType } from "@m/api/public/types";
import { dt } from "@m/utils";

import {
  ExampleEngagementBudgetSpentActivity,
  ExampleEngagementBudgetStatusChangeActivity,
  ExampleEngagementStartActivity,
  ExampleEngagementWorkStatusChangeActivity,
  ExampleEngagementWorkStatusDueDateActivity,
  ExampleEngagementWorkStatusProgressActivity,
  ExampleEngagementWorkStatusStartDateActivity,
} from "../models";

export const EXAMPLE_ENGAGEMENT_ACTIVITY_STREAM = [
  new ExampleEngagementStartActivity({
    timestamp: dt.now().minus({ month: 1 }).toISO(),
  }),
  new ExampleEngagementBudgetStatusChangeActivity({
    after: EngagementBudgetStatusType.UnderPlannedCost,
  }),
  new ExampleEngagementBudgetSpentActivity({
    timestamp: dt.now().minus({ days: 3 }).toISO(),
  }),

  new ExampleEngagementBudgetStatusChangeActivity({
    timestamp: dt.now().minus({ days: 5 }).toISO(),
    after: EngagementBudgetStatusType.OverPlannedCost,
  }),
  new ExampleEngagementWorkStatusProgressActivity({}),
  new ExampleEngagementWorkStatusDueDateActivity({
    after: dt.now().plus({ days: 10 }).toISO(),
  }),
  new ExampleEngagementWorkStatusStartDateActivity({
    after: dt.now().plus({ days: 10 }).toISO(),
  }),
  new ExampleEngagementWorkStatusChangeActivity({}),
];
