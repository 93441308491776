import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  EngagementChecklistItemNote,
  EngagementDecisionImpact,
  EngagementMilestoneNoteNode,
  EngagementMilestoneTask,
  RiskLogItemImpact,
  RiskLogItemNoteNode,
  RiskLogItemProbability,
  RiskLogItemType,
} from "@m/api/public/types";
import { useAuth } from "@m/login";
import { UserSession } from "@m/types";
import {
  Button,
  ButtonRadioGroup,
  Field,
  Fields,
  Input,
  Select,
  Textarea,
} from "@m/ui";
import { dt, formatFullDate, toProperCase } from "@m/utils";

import {
  useCreateRiskLogItem,
  useUpdateEngagementChecklistItem,
  useUpdateEngagementsDecision,
  useUpdateEngagementsMilestone,
  useUpdateRiskLogItem,
} from "../api";
import { EngagementDetailLevelIndicator } from "../components";
import {
  DETAIL_ITEM_IMPACT,
  DETAIL_ITEM_TYPE,
  ENGAGEMENTS_OWNER_OPTIONS,
} from "../constants";
import {
  EngagementDecisionType,
  EngagementDetailItemType,
  EngagementDetailOwnerType,
  EngagementsDetailsNote,
  IEngagementMilestoneItem,
  IEngagementsChecklistItem,
  IRiskLogItem,
} from "../types";

import {
  EditEngagementsDetailsNotes,
  TEMP_NOTE_ID_PREFIX,
} from "./EditEngagementsDetailsNotes";
import { EditMilestoneTasks, TEMP_TASK_ID_PREFIX } from "./EditMilestoneTasks";

export const EngagementsDetailItemForm = ({
  detailType,
  detailItem,
}: {
  detailType: DETAIL_ITEM_TYPE;
  detailItem: Partial<EngagementDetailItemType>;
}) => {
  const { engagementId } = useParams<{ engagementId: string }>();

  if (!detailItem) {
    return null;
  }

  switch (detailType) {
    case DETAIL_ITEM_TYPE.MILESTONE:
      return (
        <MilestoneForm
          engagementId={engagementId}
          milestoneItem={detailItem as IEngagementMilestoneItem}
        />
      );
    case DETAIL_ITEM_TYPE.ACTION_ITEM:
      return (
        <ActionItemForm
          engagementId={engagementId}
          actionItem={detailItem as IEngagementsChecklistItem}
        />
      );
    case DETAIL_ITEM_TYPE.RISK:
      return (
        <RiskForm
          engagementId={engagementId}
          riskLogItem={detailItem as IRiskLogItem}
        />
      );
    case DETAIL_ITEM_TYPE.DECISION:
      return (
        <DecisionForm
          engagementId={engagementId}
          decisionItem={detailItem as EngagementDecisionType}
        />
      );
    default:
      return null;
  }
};

const MilestoneForm = ({
  engagementId,
  milestoneItem,
}: {
  engagementId: string;
  milestoneItem: IEngagementMilestoneItem;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [updateMilestone] = useUpdateEngagementsMilestone(engagementId);

  const [updatedMilestone, setUpdatedMilestone] =
    useState<Partial<IEngagementMilestoneItem>>(milestoneItem);

  const isNew = !milestoneItem.id;
  const {
    title,
    dueDate,
    owner,
    percentageComplete,
    noteList = [],
    tasks = [],
  } = updatedMilestone;

  const [showTasks, setShowTasks] = useState(tasks.length > 0);
  const [showNotes, setShowNotes] = useState(noteList.length > 0);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const submitValues = {
      ...updatedMilestone,
      engagementId,
      tasks: filterAndMapTasks(tasks),
      noteList: filterAndMapNotes(noteList) as EngagementMilestoneNoteNode[],
      dueDate: dt.fromISO(dueDate).toString(),
      percentageComplete: Number(percentageComplete),
    };

    updateMilestone(submitValues).then(() => navigate(".."));
  };

  const handleUpdate = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { id, value },
    } = e;
    setUpdatedMilestone((prev) => ({ ...prev, [id]: value }));
  };

  const handleUpdateTasks = (updatedTasks: EngagementMilestoneTask[]) =>
    setUpdatedMilestone((prev: IEngagementMilestoneItem) => ({
      ...prev,
      tasks: updatedTasks,
    }));

  const handleUpdateNotes = (updatedNotes: EngagementMilestoneNoteNode[]) =>
    setUpdatedMilestone((prev: IEngagementMilestoneItem) => ({
      ...prev,
      noteList: updatedNotes,
    }));

  return (
    <form onSubmit={handleSubmit}>
      <Fields>
        <TitleField title={title} handleUpdate={handleUpdate} />
        <Fields row>
          <OwnerField
            className="w-1/2"
            user={user}
            owner={owner}
            handleUpdate={handleUpdate}
          />
          <DateField
            date={dueDate}
            htmlFor="dueDate"
            label="Due"
            className="w-1/2"
            handleUpdate={handleUpdate}
          />
        </Fields>

        <ProgressField
          className="w-1/2"
          percentageComplete={percentageComplete}
          handleUpdate={handleUpdate}
        />

        {showTasks && (
          <>
            <div className="flex gap-1 text-sm font-semibold">
              Sub-tasks
              <ChevronDownIcon className="h-2.5 w-2.5" />
            </div>
            <EditMilestoneTasks tasks={tasks} onUpdate={handleUpdateTasks} />
          </>
        )}

        {showNotes && (
          <>
            <div className="mb-1 flex gap-1 text-sm font-semibold">Notes</div>
            <EditEngagementsDetailsNotes
              notes={noteList}
              onUpdate={handleUpdateNotes}
            />
          </>
        )}

        {!showTasks && (
          <Button
            leftIcon={PlusIcon}
            className="mr-3 border-none"
            onClick={() => setShowTasks(true)}
          >
            Add subtask
          </Button>
        )}

        {!showNotes && (
          <Button
            leftIcon={PlusIcon}
            className="border-none"
            onClick={() => setShowNotes(true)}
          >
            Add notes
          </Button>
        )}

        <Button
          className="w-full"
          data-testid={`detail-${isNew ? "add" : "edit"}-submit`}
          loading={false}
          type="submit"
          kind="primary"
        >
          {isNew ? "Add Milestone" : "Update Milestone"}
        </Button>
      </Fields>
    </form>
  );
};

const ActionItemForm = ({
  engagementId,
  actionItem,
}: {
  engagementId: string;
  actionItem: IEngagementsChecklistItem;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [updateChecklistItem] = useUpdateEngagementChecklistItem(engagementId);

  const [updatedActionItem, setUpdatedActionItem] =
    useState<Partial<IEngagementsChecklistItem>>(actionItem);

  const isNew = !actionItem.id;
  const { title, dueDate, owner, noteList = [] } = updatedActionItem;

  const [showNotes, setShowNotes] = useState(noteList.length > 0);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const submitValues = {
      ...updatedActionItem,
      engagementId,
      noteList: filterAndMapNotes(noteList) as EngagementChecklistItemNote[],
      dueDate: dt.fromISO(dueDate).toString(),
    };

    updateChecklistItem(submitValues).then(() => navigate(".."));
  };

  const handleUpdate = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { id, value },
    } = e;
    setUpdatedActionItem((prev) => ({ ...prev, [id]: value }));
  };

  const handleUpdateNotes = (updatedNotes: EngagementChecklistItemNote[]) =>
    setUpdatedActionItem((prev: IEngagementsChecklistItem) => ({
      ...prev,
      noteList: updatedNotes,
    }));

  return (
    <form onSubmit={handleSubmit}>
      <Fields>
        <TitleField title={title} handleUpdate={handleUpdate} />
        <Fields row>
          <OwnerField
            className="w-1/2"
            user={user}
            owner={owner}
            handleUpdate={handleUpdate}
          />
          <DateField
            date={dueDate}
            htmlFor="dueDate"
            label="Due"
            className="w-1/2"
            handleUpdate={handleUpdate}
          />
        </Fields>

        {showNotes ? (
          <>
            <div className="mb-1 flex gap-1 text-sm font-semibold">Notes</div>
            <EditEngagementsDetailsNotes
              notes={noteList}
              onUpdate={handleUpdateNotes}
            />
          </>
        ) : (
          <Button
            leftIcon={PlusIcon}
            className="border-none"
            onClick={() => setShowNotes(true)}
          >
            Add notes
          </Button>
        )}

        <Button
          className="w-full"
          data-testid={`detail-${isNew ? "add" : "edit"}-submit`}
          loading={false}
          type="submit"
          kind="primary"
        >
          {isNew ? "Add action item" : "Update action item"}
        </Button>
      </Fields>
    </form>
  );
};

const RiskForm = ({
  engagementId,
  riskLogItem,
}: {
  engagementId: string;
  riskLogItem: IRiskLogItem;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  // Not sure why, but it seems the create and update hooks for risks were defined separately unlike the other hooks which can be used in both cases.
  // TODO(jamesmoody): See if these can be consolidated like the rest
  const [createRiskLogItem] = useCreateRiskLogItem(engagementId);
  const [updateRiskLogItem] = useUpdateRiskLogItem(engagementId);

  const [updatedRiskLogItem, setUpdatedRiskLogItem] =
    useState<Partial<IRiskLogItem>>(riskLogItem);

  const isNew = !riskLogItem.id;
  const {
    title,
    description,
    owner,
    itemType,
    dateNeeded,
    impact,
    probability,
    noteList = [],
    mitigationStrategy,
  } = updatedRiskLogItem;

  const [showNotes, setShowNotes] = useState(noteList.length > 0);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const submitValues = {
      ...updatedRiskLogItem,
      noteList: filterAndMapNotes(noteList) as RiskLogItemNoteNode[],
      dateNeeded: dt.fromISO(dateNeeded).toString(),
    };

    const createOrUpdateRiskLogItem = isNew
      ? createRiskLogItem
      : updateRiskLogItem;
    createOrUpdateRiskLogItem(submitValues).then(() => navigate(".."));
  };

  const handleUpdate = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { id, value },
    } = e;
    setUpdatedRiskLogItem((prev) => ({ ...prev, [id]: value }));
  };

  const handleUpdateType = (itemType: RiskLogItemType) => {
    setUpdatedRiskLogItem((prev: IRiskLogItem) => ({
      ...prev,
      itemType,
    }));
  };

  const handleUpdateNotes = (updatedNotes: RiskLogItemNoteNode[]) =>
    setUpdatedRiskLogItem((prev: IRiskLogItem) => ({
      ...prev,
      noteList: updatedNotes,
    }));

  return (
    <form onSubmit={handleSubmit}>
      <Fields>
        <TitleField title={title} handleUpdate={handleUpdate} />
        <DescriptionField
          description={description}
          handleUpdate={handleUpdate}
        />

        <Field label="Type">
          <ButtonRadioGroup
            name="itemType"
            onChange={handleUpdateType}
            options={[
              { value: RiskLogItemType.Budget, label: "Budget" },
              {
                value: RiskLogItemType.Schedule,
                label: "Schedule",
              },
              {
                value: RiskLogItemType.Technical,
                label: "Technical",
              },
            ]}
            value={itemType}
            width="fill"
          />
        </Field>

        <Fields row>
          <OwnerField
            className="w-1/2"
            user={user}
            owner={owner}
            handleUpdate={handleUpdate}
          />
          <DateField
            date={dateNeeded}
            htmlFor="dateNeeded"
            label="Due"
            className="w-1/2"
            handleUpdate={handleUpdate}
          />
        </Fields>

        <Fields row>
          <ItemLevelField
            value={impact}
            itemLevelType="Impact"
            className="w-1/2"
            handleUpdate={handleUpdate}
          />
          <ItemLevelField
            value={probability}
            itemLevelType="Probability"
            className="w-1/2"
            handleUpdate={handleUpdate}
          />
        </Fields>

        <Field label="Mitigation Strategy" htmlFor="mitigationStrategy">
          <Textarea
            id="mitigationStrategy"
            onChange={handleUpdate}
            value={mitigationStrategy || ""}
            rows={4}
          />
        </Field>

        {showNotes ? (
          <>
            <div className="mb-1 flex gap-1 text-sm font-semibold">Notes</div>
            <EditEngagementsDetailsNotes
              notes={noteList}
              onUpdate={handleUpdateNotes}
            />
          </>
        ) : (
          <Button
            leftIcon={PlusIcon}
            className="border-none"
            onClick={() => setShowNotes(true)}
          >
            Add notes
          </Button>
        )}

        <Button
          className="w-full"
          data-testid={`detail-${isNew ? "add" : "edit"}-submit`}
          loading={false}
          type="submit"
          kind="primary"
        >
          {isNew ? "Create Risk" : "Update Risk"}
        </Button>
      </Fields>
    </form>
  );
};

const DecisionForm = ({
  engagementId,
  decisionItem,
}: {
  engagementId: string;
  decisionItem: EngagementDecisionType;
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [updateDecision, { loading }] =
    useUpdateEngagementsDecision(engagementId);
  const [updatedDecision, setUpdatedDecision] =
    useState<Partial<EngagementDecisionType>>(decisionItem);

  const isNew = !decisionItem?.id;
  const { title, description, dueDate, impact, owner } = updatedDecision || {};

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const submitValues = {
      ...updatedDecision,
      dueDate: dt.fromISO(dueDate).toString(),
    };

    updateDecision(submitValues).then(() => navigate(".."));
  };

  const handleUpdate = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { id, value },
    } = e;
    setUpdatedDecision((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Fields>
        <TitleField title={title} handleUpdate={handleUpdate} />
        <DescriptionField
          description={description}
          handleUpdate={handleUpdate}
        />

        <Fields row>
          <ItemLevelField
            value={impact}
            itemLevelType="Impact"
            className="w-1/2"
            handleUpdate={handleUpdate}
          />
          <DateField
            date={dueDate}
            htmlFor="dueDate"
            label="Due Date"
            className="w-1/2"
            handleUpdate={handleUpdate}
          />
        </Fields>

        <OwnerField user={user} owner={owner} handleUpdate={handleUpdate} />

        <Button
          className="w-full"
          data-testid={`detail-${isNew ? "add" : "edit"}-submit`}
          loading={false}
          type="submit"
          kind="primary"
          disabled={loading}
        >
          {isNew ? "Add Decision" : "Update Decision"}
        </Button>
      </Fields>
    </form>
  );
};

const TitleField = ({
  title,
  className,
  handleUpdate,
}: {
  title: string;
  className?: string;
  handleUpdate: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Field label="Title" htmlFor="title" className={clsx(className)}>
      <Input
        id="title"
        onChange={handleUpdate}
        required={true}
        value={title || ""}
      />
    </Field>
  );
};

const DescriptionField = ({
  description,
  className,
  handleUpdate,
}: {
  description: string;
  className?: string;
  handleUpdate: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  return (
    <Field label="Description" htmlFor="description" lassName={clsx(className)}>
      <Textarea
        id="description"
        onChange={handleUpdate}
        value={description || ""}
        rows={4}
      />
    </Field>
  );
};

const OwnerField = ({
  user,
  owner,
  className,
  handleUpdate,
}: {
  user: UserSession;
  owner: EngagementDetailOwnerType;
  className?: string;
  handleUpdate: (e: ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const ownerOptions = useMemo(
    () => [
      { value: ENGAGEMENTS_OWNER_OPTIONS.MISSION, label: "Mission Cloud" },
      {
        value: ENGAGEMENTS_OWNER_OPTIONS.CUSTOMER,
        label: user?.company?.name,
      },
    ],
    [user]
  );

  return (
    <Field label="Owner" htmlFor="owner" className={clsx(className)}>
      <Select
        id="owner"
        value={owner}
        onChange={handleUpdate}
        options={ownerOptions.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      />
    </Field>
  );
};

const DateField = ({
  date,
  htmlFor,
  label,
  className,
  handleUpdate,
}: {
  date: string;
  htmlFor: string;
  label: string;
  className: string;
  handleUpdate: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const formattedDueDate = formatFullDate(date, "yyyy-MM-dd");

  return (
    <Field label={label} htmlFor={htmlFor} className={clsx(className)}>
      <Input
        id={htmlFor}
        onChange={handleUpdate}
        type="date"
        value={formattedDueDate}
      />
    </Field>
  );
};

const ItemLevelField = ({
  value,
  itemLevelType,
  className,
  handleUpdate,
}: {
  value:
    | DETAIL_ITEM_IMPACT
    | RiskLogItemImpact
    | RiskLogItemProbability
    | EngagementDecisionImpact;
  itemLevelType: "Impact" | "Probability";
  className?: string;
  handleUpdate: (e: ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const impactOptions = {
    [DETAIL_ITEM_IMPACT.LOW]: (
      <EngagementDetailLevelIndicator
        itemLevelType={itemLevelType}
        level={DETAIL_ITEM_IMPACT.LOW}
      />
    ),
    [DETAIL_ITEM_IMPACT.MEDIUM]: (
      <EngagementDetailLevelIndicator
        itemLevelType={itemLevelType}
        level={DETAIL_ITEM_IMPACT.MEDIUM}
      />
    ),
    [DETAIL_ITEM_IMPACT.HIGH]: (
      <EngagementDetailLevelIndicator
        itemLevelType={itemLevelType}
        level={DETAIL_ITEM_IMPACT.HIGH}
      />
    ),
  };

  return (
    <Field
      htmlFor={itemLevelType.toLowerCase()}
      label={itemLevelType}
      className={clsx(className)}
    >
      <Select
        id={itemLevelType.toLowerCase()}
        value={value}
        onChange={handleUpdate}
        options={Object.keys(impactOptions).map((level) => {
          return (
            <option key={level} value={level}>
              {toProperCase(level)}
            </option>
          );
        })}
      />
    </Field>
  );
};

const ProgressField = ({
  percentageComplete,
  className,
  handleUpdate,
}: {
  percentageComplete: number;
  className?: string;
  handleUpdate: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Field
      htmlFor="percentageComplete"
      label="Progress"
      className={clsx(className)}
    >
      <Input
        id="percentageComplete"
        className="text-right"
        maxLength={3}
        minLength={0}
        onChange={handleUpdate}
        required={true}
        placeholder="0"
        value={percentageComplete}
        rightIcon={PercentIcon}
      />
    </Field>
  );
};

// Surprisingly, heroicons does not have a percent icon so we'll need to make a component for it in order to pass it to Input
const PercentIcon = () => (
  <div className="text-sm font-semibold text-default">%</div>
);

const filterAndMapTasks = (tasks: EngagementMilestoneTask[]) =>
  tasks
    .filter((task) => task.title !== "") // remove empty tasks
    .map((task) => {
      const newUpdates = {
        title: task.title,
        complete: task.complete,
      } as EngagementMilestoneTask;
      return task?.id?.includes(TEMP_TASK_ID_PREFIX) // Task is new and should have temp id removed
        ? newUpdates
        : {
            ...newUpdates,
            id: task.id,
          };
    });

const filterAndMapNotes = (notes: EngagementsDetailsNote[]) =>
  notes
    .filter((note) => note.content !== "") // remove empty notes
    .map((note) => {
      const newUpdates = {
        content: note.content,
      } as EngagementsDetailsNote;
      return note?.id?.includes(TEMP_NOTE_ID_PREFIX) // Note is new and should have temp id removed
        ? newUpdates
        : {
            ...newUpdates,
            id: note.id,
          };
    });
