export const FEATURE_FLAGS = {
  ACCOUNTS_ADD_AWS_ACCOUNT: "add-aws-account",
  ACCOUNTS_CONNECT_AWS_ACCOUNT: "accounts-connect-aws-account",
  CASES: "cases",
  CREDIT_CARD_PAYMENT_SUPPORT: "credit-card-payment-support",
  DASHBOARD: "dashboard",
  DASHBOARD_COST_SUMMARY_WIDGET_SHOW: "dashboard-cost-summary-widget-show",
  DEVOPS_DESK: "devops-desk",
  ENGAGEMENTS: "engagements",
  MISSION_CONTROL_DIAGNOSTICS: "mission-control-diagnostics",
  MISSION_CONTROL_LIVE_CHAT: "mission-control-live-chat",
  MISSION_CONTROL_STAFF_LOUNGE: "mission-control-staff-lounge",
  RESOURCES: "resources",
  SCORECARDS: "scorecards",
  SCORECARD_RECOMMENDATIONS: "scorecard-recommendations",
  SUPPORT_CASES_ALLOW_OPERATIONAL_SUPPORT: "operational-support",
  SUPPORT_CASES_ALLOW_TECHNICAL_SUPPORT: "technical-support",
};
