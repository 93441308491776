import { PATHS } from "@mc/constants";
import { useMarketplaceRegistrations } from "@mc/features/Onboarding";
import { Navigate } from "@mc/router";

/** Redirects to the onboarding welcome page, if the user has pending onboardings. */
export const OnboardingPathRedirect = () => {
  const { hasPendingOnboardings } = useMarketplaceRegistrations();

  if (hasPendingOnboardings)
    return <Navigate replace to={PATHS.ONBOARDING_WELCOME} />;

  return <Navigate replace to={PATHS.ROOT} />;
};
