import {
  EngagementMilestoneOwner,
  EngagementSubtype,
  EngagementTemplateFill,
  EngagementTemplateFillField,
  EngagementTemplateFillFieldConnection,
  EngagementType,
} from "@m/api/public/types";
import { generateMockId } from "@m/api/testing";

class ExampleTemplateFillField implements EngagementTemplateFillField {
  engagementTemplateFillId: number;
  fieldName: string;
  id: string;
  value: string;

  constructor({ engagementTemplateFillId, fieldName, value }) {
    this.engagementTemplateFillId = engagementTemplateFillId;
    this.fieldName = fieldName;
    this.id = generateMockId();
    this.value = value;
  }
}

class ExampleEngagementTemplateFill implements EngagementTemplateFill {
  engagementSubtype: EngagementSubtype;
  engagementType: EngagementType;
  fillFields?: EngagementTemplateFillFieldConnection;
  id: string;
  order: number;

  constructor({ engagementSubtype, engagementType, fillFields, order }) {
    this.engagementSubtype = engagementSubtype;
    this.engagementType = engagementType;
    this.fillFields = fillFields;
    this.id = generateMockId();
    this.order = order;
  }
}

export const EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_FIELD_TITLE =
  new ExampleTemplateFillField({
    fieldName: "title",
    value: "Initial Discovery",
    engagementTemplateFillId: 0,
  });

export const EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_FIELD_TITLE_2 =
  new ExampleTemplateFillField({
    fieldName: "title",
    value: "Discovery Alignment Checkpoint",
    engagementTemplateFillId: 0,
  });

const EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_FIELD_OWNER =
  new ExampleTemplateFillField({
    fieldName: "owner",
    value: EngagementMilestoneOwner.Mission,
    engagementTemplateFillId: 0,
  });

export const EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_ASSESS_MILESTONE =
  new ExampleEngagementTemplateFill({
    engagementSubtype: EngagementSubtype.Milestone,
    engagementType: EngagementType.Assess,
    fillFields: {
      edges: [
        { node: EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_FIELD_TITLE },
        { node: EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_FIELD_OWNER },
      ],
    },
    order: 1,
  });

export const EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_ASSESS_MILESTONE_2 =
  new ExampleEngagementTemplateFill({
    engagementSubtype: EngagementSubtype.Milestone,
    engagementType: EngagementType.Assess,
    fillFields: {
      edges: [
        { node: EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_FIELD_TITLE_2 },
        { node: EXAMPLE_ENGAGEMENT_TEMPLATE_FILL_FIELD_OWNER },
      ],
    },
    order: 2,
  });
