import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { EligibleProductsForInvitationQuery } from "@m/api/public/types";

import { SUPPORTED_PRODUCT_INVITATION_SKUS } from "@mc/constants";

export const ELIGIBLE_PRODUCTS_FOR_INVITATION = gql(/* GraphQL */ `
  query EligibleProductsForInvitation {
    userProfile {
      currentCompany {
        eligibleProductsForInvitations {
          edges {
            node {
              id
              displayName
              sku
            }
          }
        }
      }
    }
  }
`);

export type EligibleProductsForInvitationType = Array<
  EligibleProductsForInvitationQuery["userProfile"]["currentCompany"]["eligibleProductsForInvitations"]["edges"][number]["node"]
>;

export const useEligibleProductsForInvitation = () => {
  const { data, ...result } = useQuery(ELIGIBLE_PRODUCTS_FOR_INVITATION);

  const eligibleProductsForInvitation =
    data?.userProfile?.currentCompany?.eligibleProductsForInvitations?.edges.map(
      (edge) => edge.node
    ) || [];

  const supportedProducts = eligibleProductsForInvitation.filter(({ sku }) =>
    SUPPORTED_PRODUCT_INVITATION_SKUS.includes(sku)
  );

  return {
    data: supportedProducts,
    ...result,
  };
};
